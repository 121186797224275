import React, { useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Container, Toolbar } from "@mui/material";
import theme from "theme";
// import { storeContext } from "../provider/Provider";
// import { authActions } from "../../store/auth-reducer";
import Sidebar from "./Sidebar";

const Layout = ({ childElement, path }) => {
  const navigate = useNavigate();
  // const { dispatch } = React.useContext(storeContext);
  const [cookies] = useCookies(["auth"]);
  if (cookies.id) {
    axios.defaults.headers.common.Authorization = `Bearer ${cookies.id}`;
  }

  // const getData = () => {
  //   dispatch({
  //     type: authActions.AUTH_SET_ALL,
  //     payload: {
  //       user: { name: "Ajri Sylhasi", email: "ajrisylhasi@gmail.com" },
  //     },
  //   });
  // };

  useEffect(() => {
    if (cookies.id) {
      navigate(`/${path}`);
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "auto",
          width: "calc(100% - 72px)",
        }}
      >
        <Toolbar />
        <Container
          maxWidth="xl"
          sx={{
            px: "40px",
            [theme.breakpoints.down("sm")]: {
              px: "20px",
            },
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "calc(100% - 64px)",
            overflow: "scroll",
          }}
        >
          {childElement}
        </Container>
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  childElement: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

export default Layout;
