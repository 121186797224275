import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import { Box, Button } from "@mui/material";
import axios from "axios";
import { productsActions } from "store/products-reducer";
import { storeContext } from "components/provider/Provider";
import { layoutActions } from "store/layouts-reducer";
import { LucidePenSquare, LucidePlusCircle, LucideTrash2 } from "lucide-react";
import ProductForm from "components/products/ProductForm";

const { REACT_APP_API_URL } = process.env;

const extractProductSizes = (product) => {
  const productSizes = [];
  product.variants.forEach((variant) => {
    variant.option_values.forEach((optionValue) => {
      if (!productSizes.includes(optionValue.presentation)) {
        productSizes.push(optionValue.presentation);
      }
    });
  });

  return productSizes.join(", ");
};

const extractVariantSizes = (product) => {
  const variants = product.variants.map((v) => ({
    id: v.id,
    size: v.option_values.find(
      (o) => o.option_type_name.split("-")[1] === "size"
    ).presentation,
  }));

  return variants;
};

const extractProductGender = (product) => {
  const gender = product.classifications.find((c) =>
    c?.taxon?.pretty_name.includes("Gjinia")
  )?.taxon.name;

  return gender;
};

const handleProductObject = (product) => ({
  id: product.id,
  name: product.name,
  thumb:
    product?.master?.images[0]?.mini_url ||
    product?.variants[0]?.images[0]?.mini_url,
  images: product?.master?.images,
  sku: product?.master?.sku,
  price: product?.master?.price,
  salePrice: product?.master?.cost_price,
  gender: extractProductGender(product),
  category: product?.classifications[0]?.taxon?.name,
  sizes: extractProductSizes(product),
  variants: extractVariantSizes(product),
});

const Products = () => {
  const { state, dispatch } = useContext(storeContext);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [paginationTotal, setPaginationTotal] = useState(0);

  const loadProductsData = () => {
    setLoading(true);
    axios
      .get(`${REACT_APP_API_URL}/api/products`, {
        params: {
          page,
          per_page: pageSize,
        },
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setReload(false);
          setLoading(false);
          dispatch({
            type: productsActions.PRODUCTS_SET_ALL,
            payload: {
              allProducts: res.data.products.map((product) =>
                handleProductObject(product)
              ),
            },
          });
          setPaginationTotal(res.data.total_count);
        }
      });
  };

  useEffect(() => {
    dispatch({
      type: layoutActions.LAYOUT_SET_ALL,
      payload: {
        pageTitle: "Produktet",
      },
    });

    loadProductsData();
  }, []);

  useEffect(() => {
    loadProductsData();
  }, [reload === true]);

  const openPopup = (product = null) => {
    setSelectedProduct(product);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleProductDelete = (productId) => {
    axios
      .delete(`${REACT_APP_API_URL}/api/products/${productId}`)
      .then(() => {
        const updatedProducts = state.products.allProducts.filter(
          (p) => p?.id !== productId
        );
        dispatch({
          type: productsActions.PRODUCTS_SET_ALL,
          payload: {
            allProducts: updatedProducts,
          },
        });
        dispatch({
          type: layoutActions.LAYOUT_SET_ALL,
          payload: {
            openMessage: true,
            error: false,
            signalMessage: "Produkti u fshi me sukses!",
          },
        });
      })
      .catch(() => {
        dispatch({
          type: layoutActions.LAYOUT_SET_ALL,
          payload: {
            openMessage: true,
            error: true,
            signalMessage: "Ka ndodhur një gabim gjatë fshirjes!",
          },
        });
      });
  };

  const columns = [
    { field: "id", headerName: "ID", minWidth: 100 },
    {
      field: "thumb",
      headerName: "Fotografia",
      sortable: false,
      editable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <img src={params.value} alt={`Thumbnail for ${params.row.name}`} />
      ),
    },
    {
      field: "name",
      headerName: "Emri",
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "price",
      headerName: "Çmimi",
      sortable: true,
      type: "number",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "salePrice",
      headerName: "Çmimi me Zbritje",
      sortable: true,
      type: "number",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "category",
      headerName: "Kategoria",
      sortable: false,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "gender",
      headerName: "Gjinia",
      sortable: false,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "sizes",
      headerName: "Madhësitë",
      sortable: false,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "thumb",
      headerName: "Fotografia",
      sortable: false,
      editable: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <img src={params.value} alt={`Thumbnail for ${params.row.name}`} />
      ),
    },
    {
      field: "productFunction",
      description: "Funksionet e Produktit",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => "",
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <IconButton onClick={() => openPopup(params.row)}>
            <LucidePenSquare />
          </IconButton>
          <IconButton onClick={() => handleProductDelete(params.row.id)}>
            <LucideTrash2 />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Grid container sx={{ maxHeight: "100%" }}>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
        }}
      >
        <Box display="flex" justifyContent="flex-end" my="24px">
          <Button
            variant="contained"
            sx={{ display: "flex", gap: "10px", fontWeight: "600" }}
            onClick={() => openPopup()}
          >
            Shto Produkt <LucidePlusCircle strokeWidth="1.5" />
          </Button>
        </Box>
        <DataGrid
          rows={state.products?.allProducts}
          columns={columns}
          sx={{
            flex: 1,
            marginBottom: "24px",
          }}
          loading={loading}
          rowHeight={80}
          disableRowSelectionOnClick
          pagination
          paginationMode="server"
          page={page}
          rowCount={paginationTotal}
          onPaginationModelChange={(newPagination) => {
            setPage(newPagination.page + 1);
            setPageSize(newPagination.pageSize);
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
        <Dialog
          PaperProps={{
            style: {
              borderRadius: "25px",
              padding: "30px",
            },
          }}
          fullWidth
          open={isPopupOpen}
          onClose={closePopup}
        >
          <ProductForm
            selectedProduct={selectedProduct}
            setReload={setReload}
            closePopup={closePopup}
          />
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default Products;
