import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { layoutActions } from "store/layouts-reducer";
import { storeContext } from "components/provider/Provider";

const Orders = () => {
  const [pageBuilding, setPageBuilding] = useState();
  const { dispatch } = useContext(storeContext);

  useEffect(() => {
    setPageBuilding(true);

    dispatch({
      type: layoutActions.LAYOUT_SET_ALL,
      payload: {
        pageTitle: "Porosite",
      },
    });
  }, []);
  return (
    <Box>
      {pageBuilding ? (
        <p>Se Shpejti</p>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <DataGrid
              rows={[]}
              columns={[]}
              pageSize={5}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Orders;
