import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Link as RouterLink } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import AppBar from "shared/components/AppBar";
import Drawer from "shared/components/Drawer";
import MainListItems from "components/layout/MainListItems";
import SecondaryListItems from "components/layout/SecondaryListItems";
import { storeContext } from "components/provider/Provider";
import MobileDrawer from "components/layout/MobileDrawer";
import theme from "theme";
import logoWide from "assets/images/logo-wide.svg";
import { Link } from "@mui/material";
import { LucideBell, LucideMenu } from "lucide-react";

const Sidebar = () => {
  const [open, setOpen] = React.useState(false);
  const { state } = useContext(storeContext);

  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const drawerContent = () => (
    <>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          px: [1],
        }}
      >
        <Box
          width="60%"
          display="flex"
          justifyContent="left"
          marginLeft="16px"
          alignItems="center"
        >
          <Link component={RouterLink} to="/" maxHeight="35px">
            <img src={logoWide} alt="VeshDesh Logo" height="35px" />
          </Link>
        </Box>
        <Box width="40%" textAlign="right">
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon color={open ? "inherit" : "white"} />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />
      <List component="nav">
        <MainListItems closeDrawer={closeDrawer} />
        <Divider sx={{ my: 1 }} />
        <SecondaryListItems closeDrawer={closeDrawer} />
      </List>
    </>
  );

  return (
    <>
      <AppBar position="absolute" open={open && !isPhone}>
        <Toolbar
          sx={{
            pr: "24px",
            color: "white",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              marginLeft: "-9px",
              ...(open && !isPhone && { display: "none" }),
            }}
          >
            <LucideMenu color="white" />
          </IconButton>
          <Typography
            component="h1"
            variant="h5"
            color={theme.palette.white.main}
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {state.layout.pageTitle}
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={0}>
              <LucideBell color="white" />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      {isPhone ? (
        <MobileDrawer open={open} closeDrawer={closeDrawer}>
          {drawerContent()}
        </MobileDrawer>
      ) : (
        <Drawer variant="permanent" open={open}>
          {drawerContent()}
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
