import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import {
  Badge,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Dropzone from "react-dropzone";
import axios from "axios";
import { productsActions } from "store/products-reducer";
import { storeContext } from "components/provider/Provider";
import { layoutActions } from "store/layouts-reducer";
import { LucideAlertCircle, LucideImagePlus, X } from "lucide-react";
import theme from "theme";

const { REACT_APP_API_URL } = process.env;

const ProductForm = ({ selectedProduct, setReload, closePopup }) => {
  const { state, dispatch } = useContext(storeContext);
  const [productName, setProductName] = useState(selectedProduct?.name || "");
  const [productPrice, setProductPrice] = useState(
    selectedProduct?.price || ""
  );
  const [productSalePrice, setProductSalePrice] = useState(
    selectedProduct?.salePrice || ""
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [initialSizes, setInitialSizes] = useState([]);
  const [allSizes, setAllSizes] = useState([]);
  const [acceptedFiles, setAcceptedFiles] = useState(
    selectedProduct?.images.map((i) => ({ id: i.id, url: i.product_url })) || []
  );
  const [productValid, setProductValid] = useState(false);
  const [productErrors, setProductErrors] = useState([]);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/api/taxons/`)
      .then((res) => {
        if (res.status === 200) {
          const categories = res.data.taxons.filter(
            (taxon) => taxon.taxonomy_id === 1 && taxon.taxons.length === 0
          );
          dispatch({
            type: productsActions.PRODUCTS_SET_ALL,
            payload: {
              allCategories: categories,
            },
          });
          if (selectedProduct && selectedProduct?.category) {
            setSelectedCategory(
              categories.find((c) => c.name === selectedProduct?.category)
            );
          }
        }
      })
      .catch(() => {});

    axios
      .get(`${REACT_APP_API_URL}/api/taxonomies/`)
      .then((res) => {
        if (res.status === 200) {
          const genders = res.data.taxonomies.filter(
            (tax) => tax.name === "Gjinia"
          )[0]?.root?.taxons;
          dispatch({
            type: productsActions.PRODUCTS_SET_ALL,
            payload: {
              allGenders: genders,
            },
          });
          if (selectedProduct && selectedProduct?.gender) {
            setSelectedGender(
              genders.find((c) => c.name === selectedProduct?.gender)
            );
          }
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (selectedCategory && selectedCategory.permalink) {
      setSelectedSizes([]);
      const categoryName = selectedCategory?.permalink.split("/")[1];
      axios
        .get(
          `${REACT_APP_API_URL}/api/option_types/?q[name_cont]=${categoryName}`
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: productsActions.PRODUCTS_SET_ALL,
              payload: {
                allSizes: res.data[0]?.option_values,
              },
            });
            if (selectedProduct && selectedProduct?.sizes) {
              const productSizes = selectedProduct?.sizes.split(", ");
              setInitialSizes(
                res.data[0]?.option_values.filter((c) =>
                  productSizes.includes(c.presentation)
                )
              );
              setSelectedSizes(
                res.data[0]?.option_values.filter((c) =>
                  productSizes.includes(c.presentation)
                )
              );
            }
            setAllSizes(res.data[0]?.option_values);
          }
        })
        .catch(() => {});
    }
  }, [selectedCategory]);

  const handleSizeClick = (size) => {
    if (selectedSizes.includes(size)) {
      setSelectedSizes(selectedSizes.filter((s) => s !== size));
    } else {
      setSelectedSizes([...selectedSizes, size]);
    }
  };

  const handleAcceptedFiles = (files) => {
    setAcceptedFiles([...acceptedFiles, ...files]);
  };

  const disabledTaxon = (taxon) => taxon.taxons.length !== 0;

  const handleSelectCategory = (taxon) => {
    setSelectedCategory(taxon);
  };

  const handleSelectGender = (taxon) => {
    setSelectedGender(taxon);
  };

  const handleDeleteImage = (file) => {
    if (file.id && selectedProduct) {
      axios
        .delete(
          `${REACT_APP_API_URL}/api/products/${selectedProduct.id}/images/${file.id}`
        )
        .then(() => {
          const updatedImages = acceptedFiles.filter((f) => f !== file);
          setAcceptedFiles(updatedImages);
          dispatch({
            type: layoutActions.LAYOUT_SET_ALL,
            payload: {
              openMessage: true,
              error: false,
              signalMessage: "Fotografia u fshi me sukses!",
            },
          });
        })
        .catch(() => {
          dispatch({
            type: layoutActions.LAYOUT_SET_ALL,
            payload: {
              openMessage: true,
              error: true,
              signalMessage: "Ka ndodhur një gabim gjatë fshirjes!",
            },
          });
        });
    } else {
      const updatedImages = acceptedFiles.filter((f) => f !== file);
      setAcceptedFiles(updatedImages);
    }
  };

  const postProductImages = async (res) => {
    Promise.all(
      acceptedFiles
        .filter((f) => f.path)
        .map((file) =>
          axios
            .post(
              `${REACT_APP_API_URL}/api/products/${res.data?.id}/images`,
              {
                image: {
                  attachment: file,
                  ...(selectedProduct && {
                    position: acceptedFiles.indexOf(file) + 1,
                  }),
                },
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              dispatch({
                type: layoutActions.LAYOUT_SET_ALL,
                payload: {
                  openMessage: true,
                  error: false,
                  signalMessage: "Fotot e produktit u rajtën!",
                },
              });
            })
            .catch(() => {
              dispatch({
                type: layoutActions.LAYOUT_SET_ALL,
                payload: {
                  openMessage: true,
                  error: true,
                  signalMessage: "Ka ndodhë një gabim.",
                },
              });
            })
        )
    );
  };

  const postApi = (url, postData) => axios.post(url, postData);
  const patchApi = (url, patchData) => axios.patch(url, patchData);

  const deleteOldVariants = () => {
    initialSizes
      .filter((s) => !selectedSizes.includes(s))
      .forEach((size) => {
        const sizeVariant = selectedProduct.variants.find(
          (v) => v.size === size.presentation
        );
        axios.delete(`${REACT_APP_API_URL}/api/variants/${sizeVariant.id}`);
      });
  };

  const postFunc = async (res) => {
    dispatch({
      type: layoutActions.LAYOUT_SET_ALL,
      payload: {
        openMessage: true,
        error: false,
        signalMessage: "Produkti u ruajt!",
      },
    });
    setReload(true);
    deleteOldVariants();
    postProductImages(res);
    closePopup();
  };

  const handleUploadedProductValidation = () => {
    const errors = [];
    if (productName === "") {
      errors.push("Emri i produktit është i zbrazët.");
    }
    if (productPrice === "") {
      errors.push("Çmimi i produktit është i zbrazët.");
    }
    if (selectedGender === null) {
      errors.push("Gjinia e produktit është e zbrazët.");
    }
    if (selectedCategory === null) {
      errors.push("Kategoria e produktit është e zbrazët.");
    }
    if (selectedCategory && selectedSizes.length < 1) {
      errors.push("Madhësite e produktit janë të zbrazëta.");
    }
    setProductErrors(errors);
    setProductValid(errors.length < 1);
  };

  useEffect(() => {
    handleUploadedProductValidation();
  }, [
    productName,
    productPrice,
    selectedCategory,
    selectedGender,
    selectedSizes,
  ]);

  const handleUploadProduct = () => {
    const categoryName = selectedCategory?.permalink.split("/")[1];
    const data = {
      product: {
        name: productName,
        price: productPrice,
        cost_price: productSalePrice,
        shipping_category_id: 1,
        option_types: [`${categoryName}-size`],
        taxon_ids: [selectedCategory?.id, selectedGender?.id]
          .filter((t) => !!t)
          .join(", "),
        variants: selectedSizes
          .filter((s) => !initialSizes.includes(s))
          .map((size) => ({
            track_inventory: true,
            price: productPrice,
            cost_price: productSalePrice,
            options: [
              {
                name: `${categoryName}-size`,
                value: size.name,
              },
            ],
          })),
      },
    };
    if (productValid) {
      if (selectedProduct) {
        patchApi(
          `${REACT_APP_API_URL}/api/products/${selectedProduct.id}`,
          data
        )
          .then(postFunc)
          .catch(() => {
            dispatch({
              type: layoutActions.LAYOUT_SET_ALL,
              payload: {
                openMessage: true,
                error: true,
                signalMessage: "Ka ndodhë një gabim.",
              },
            });
          });
      } else {
        postApi(`${REACT_APP_API_URL}/api/products/`, data)
          .then(postFunc)
          .catch(() => {
            dispatch({
              type: layoutActions.LAYOUT_SET_ALL,
              payload: {
                openMessage: true,
                error: true,
                signalMessage: "Ka ndodhë një gabim.",
              },
            });
          });
      }
    }
  };

  return (
    <DialogContent sx={{ padding: "0px" }}>
      <form>
        <Box
          display="flex"
          width="100%"
          flexDirection="column"
          gap="25px"
          paddingTop="5px"
          marginBottom="25px"
        >
          <TextField
            label="Emri"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            fullWidth
          />
          <TextField
            label="Çmimi"
            type="number"
            value={productPrice}
            onChange={(e) => setProductPrice(e.target.value)}
            fullWidth
          />
          <TextField
            label="Çmimi me zbritje"
            type="number"
            value={productSalePrice}
            onChange={(e) => setProductSalePrice(e.target.value)}
            fullWidth
          />

          {state.products.allGenders && (
            <FormControl>
              <InputLabel>Gjinia</InputLabel>
              <Select fullWidth label="Gjinia" value={selectedGender?.id || ""}>
                {state.products.allGenders.map((taxon) => (
                  <MenuItem
                    key={taxon.id}
                    value={taxon.id}
                    onClick={() => handleSelectGender(taxon)}
                  >
                    {taxon.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {state.products.allCategories && (
            <FormControl>
              <InputLabel>Kategoria</InputLabel>
              <Select
                label="Kategoria"
                fullWidth
                value={selectedCategory?.id || ""}
              >
                {state.products.allCategories.map((taxon) => (
                  <MenuItem
                    key={taxon.id}
                    value={taxon.id}
                    disabled={disabledTaxon(taxon)}
                    onClick={() => handleSelectCategory(taxon)}
                  >
                    {taxon.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedCategory && (
            <FormControl>
              <InputLabel>Madhësia</InputLabel>
              <Select
                label="Madhësia"
                fullWidth
                value={selectedSizes || ""}
                onChange={() => {}}
                multiple
                renderValue={(selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap", zIndex: 2 }}>
                    {selected.map((value) => {
                      const selectedSize = allSizes.find(
                        (size) => size.id === value.id
                      );
                      return (
                        <div
                          key={value.id}
                          style={{ margin: "2px", pointerEvents: "none" }}
                        >
                          <Chip
                            key={value.id}
                            label={selectedSize?.name}
                            style={{ pointerEvents: "auto" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              >
                {state.products.allSizes?.map((size) => (
                  <MenuItem
                    key={size.id}
                    value={size}
                    selected
                    onClick={() => {
                      handleSizeClick(size);
                    }}
                  >
                    {size.presentation}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Dropzone onDrop={handleAcceptedFiles} accept={{ "image/*": [] }}>
            {({ getRootProps, getInputProps }) => (
              <Box
                sx={{
                  backgroundColor: theme.palette.white.light,
                  padding: "24px",
                  borderRadius: "55px",
                  cursor: "pointer",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Typography
                  variant="body1"
                  display="flex"
                  gap="10px"
                  justifyContent="center"
                >
                  {selectedProduct ? "Ndrysho Fotot" : "Shto Fotot"}
                  <LucideImagePlus strokeWidth="1.5" />
                </Typography>
              </Box>
            )}
          </Dropzone>
          {acceptedFiles.length > 0 && (
            <Box>
              <Typography variant="h5">Fotot e shtuara:</Typography>
              <Box display="flex" flexWrap="wrap" gap="10px" mt="20px">
                {acceptedFiles.map((file) => (
                  <Badge
                    badgeContent={
                      <IconButton
                        onClick={() => handleDeleteImage(file)}
                        color="white"
                        sx={{
                          backgroundColor: theme.palette.error.light,
                          "&:hover": {
                            backgroundColor: theme.palette.error.light,
                          },
                          maxWidth: 30,
                          maxHeight: 30,
                        }}
                      >
                        <X />
                      </IconButton>
                    }
                    key={file.url ? file.url : URL.createObjectURL(file)}
                  >
                    <img
                      key={file.url ? file.url : URL.createObjectURL(file)}
                      src={file.url ? file.url : URL.createObjectURL(file)}
                      alt={file.name}
                      style={{
                        borderRadius: "10px",
                        ...(acceptedFiles[0] === file && {
                          border: `solid 2px ${theme.palette.primary.main}`,
                        }),
                      }}
                      height="120"
                    />
                  </Badge>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          {productErrors.map((e) => (
            <Box display="flex" gap="10px" alignItems="center" key={e}>
              <LucideAlertCircle width={20} color="red" />
              <Typography variant="caption" color="red">
                {e}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleUploadProduct}
            disabled={!productValid}
          >
            {selectedProduct ? "Ndrysho Produktin" : "Shto Produktin"}
          </Button>
        </Box>
      </form>
    </DialogContent>
  );
};

ProductForm.propTypes = {
  selectedProduct: PropTypes.node || null,
  setReload: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

ProductForm.defaultProps = {
  selectedProduct: null,
};

export default ProductForm;
