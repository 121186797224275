import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { LucideLogOut } from "lucide-react";
import { authActions } from "store/auth-reducer";
import { productsActions } from "store/products-reducer";
import { stockActions } from "store/stock-reducer";
import { layoutActions } from "store/layouts-reducer";
import { storeContext } from "components/provider/Provider";

const SecondaryListItems = () => {
  const { dispatch } = React.useContext(storeContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch({ type: authActions.LOGOUT });
    dispatch({ type: productsActions.CLEAR_PRODUCTS });
    dispatch({ type: stockActions.CLEAR_STOCK });
    dispatch({ type: layoutActions.CLEAR_LAYOUT });
    navigate("/login");
  };

  return (
    <ListItemButton onClick={handleLogout}>
      <ListItemIcon>
        <LucideLogOut />
      </ListItemIcon>
      <ListItemText primary="Dilni" />
    </ListItemButton>
  );
};
export default SecondaryListItems;
