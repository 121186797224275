import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#E7F4E6",
      main: "#60B45A",
      transparent: "rgb(96, 180, 90, 0.1)",
      contrastText: "#262A26",
      darkOlive: "#BECEBD",
    },
    white: {
      main: "#FFF",
      light: "#F5F5F5",
      darker: "#D9D9D9",
      contrast: "#000",
      fade: "rgb(0, 0, 0, 0.3)",
      transparent10: "rgb(255, 255, 255, 0.1)",
      transparent30: "rgb(255, 255, 255, 0.3)",
      transparent75: "rgb(255, 255, 255, 0.75)",
    },
    secondary: {
      main: "#262A26",
      light: "#616B61",
      contrastText: "#FFF",
    },
    error: {
      main: "#FF6542",
      contrastText: "#EFF6EE",
    },
    warning: {
      main: "#FF6542",
    },
  },
  typography: {
    fontFamily: ["'DM Sans', sans-serif"],
  },
  breakpoints: {
    values: {
      start: 0,
      xs: 500,
      sm: 768,
      md: 992,
      lg: 1260,
      xl: 1920,
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingLeft: "22px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          "& input": {
            padding: "16px 24px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: "12px 24px",
          borderRadius: "100px",
          color: "white",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

export default theme;
