import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { storeContext } from "components/provider/Provider";
import { layoutActions } from "store/layouts-reducer";
import Copyright from "shared/components/Copyright";
import { InputAdornment } from "@mui/material";
import kosovo from "assets/images/kosovo.webp";
import theme from "theme";
import { LucideStore, LucideUser2 } from "lucide-react";

const { REACT_APP_API_URL } = process.env;

const Register = () => {
  const { dispatch } = useContext(storeContext);
  const navigate = useNavigate();
  const [type, setType] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [identification, setIdentification] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [storeName, setStoreName] = useState("");
  const [storeLink, setStoreLink] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      tenant: {
        name: storeName || `${firstName} ${lastName}`,
        subdomain: storeLink,
        tenant_type: type,
      },
      user: {
        name: `${firstName} ${lastName}`,
        phone,
        identification,
        email,
        password,
        password_confirmation: passwordConfirmation,
      },
    };
    axios
      .post(`${REACT_APP_API_URL}/api/registration/`, data)
      .then(() => {
        navigate("/login");
        dispatch({
          type: layoutActions.LAYOUT_SET_ALL,
          payload: {
            openMessage: true,
            error: false,
            signalMessage: "Llogaria u regjistrua!",
          },
        });
      })
      .catch(() => {
        dispatch({
          type: layoutActions.LAYOUT_SET_ALL,
          payload: {
            openMessage: true,
            error: true,
            signalMessage:
              "Ka ndodhë një gabim. Ju lutemi, rishikoni detajet dhe provoni prap.",
          },
        });
      });
  };

  useEffect(() => {
    const formattedStoreName = storeName.split(" ").join("-")?.toLowerCase();
    setStoreLink(formattedStoreName);
  }, [storeName]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          my: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Regjistrohuni
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item start={12} sm={6}>
              <TextField
                required
                fullWidth
                id="first-name"
                label="Emri"
                name="first-name"
                autoComplete="first-name"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </Grid>
            <Grid item start={12} sm={6}>
              <TextField
                required
                fullWidth
                name="surname"
                label="Mbiemri"
                id="surname"
                autoComplete="surname"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </Grid>
            <Grid item start={12} sm={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="50px"
                width="100%"
                onClick={() => setType("shop")}
                sx={{
                  transition: "all 0.3s",
                  backgroundColor: theme.palette.white.light,
                  border: `solid 1px ${theme.palette.white.light}`,
                  borderRadius: 50,
                  ...(type === "shop" && {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.white.main,
                  }),
                }}
              >
                <LucideStore width="18px" />
                <Typography variant="caption">Dyqan</Typography>
              </Box>
            </Grid>
            <Grid item start={12} sm={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height="50px"
                width="100%"
                alignItems="center"
                onClick={() => setType("individual")}
                sx={{
                  transition: "all 0.5s",
                  backgroundColor: theme.palette.white.light,
                  border: `solid 1px ${theme.palette.white.light}`,
                  borderRadius: 50,
                  ...(type === "individual" && {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.white.main,
                  }),
                }}
              >
                <LucideUser2 width="18px" />
                <Typography variant="caption">Individ</Typography>
              </Box>
            </Grid>
            {type === "shop" && (
              <Grid item start={12}>
                <TextField
                  required
                  fullWidth
                  id="shop-name"
                  label="Emri i Dyqanit"
                  name="shop-name"
                  autoComplete="shop-name"
                  value={storeName}
                  onChange={(event) => setStoreName(event.target.value)}
                />
                <Typography
                  variant="caption"
                  color="primary"
                  sx={{ paddingLeft: "15px" }}
                >
                  Linku i Dyqanit: https://veshdesh.com/stores/{storeLink}
                </Typography>
              </Grid>
            )}

            <Grid item start={12}>
              <TextField
                required
                fullWidth
                name="identification"
                type="number"
                label="Numri Personal"
                id="identification"
                autoComplete="identification"
                value={identification}
                onChange={(event) => setIdentification(event.target.value)}
              />
            </Grid>
            <Grid item start={12}>
              <TextField
                required
                fullWidth
                name="phone"
                type="number"
                label="Telefoni"
                placeholder="45 123 234"
                id="phone"
                autoComplete="phone"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      diplay="flex"
                      sx={{ gap: "5px" }}
                    >
                      <img src={kosovo} alt="Kosovo flag" width="24" /> (+383)
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item start={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Adresa"
                name="email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item start={12} sm={6}>
              <TextField
                required
                fullWidth
                name="password"
                label="Fjalëkalimi"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Grid>
            <Grid item start={12} sm={6}>
              <TextField
                required
                fullWidth
                name="password-confirmation"
                label="Konfirmimi"
                type="password"
                id="password-confirmation"
                autoComplete="password-confirmation"
                value={passwordConfirmation}
                onChange={(event) =>
                  setPasswordConfirmation(event.target.value)
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Regjistrohuni
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link component={RouterLink} to="/login" variant="body2">
                Vetëm se jeni shitës? Kyquni
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright />
    </Container>
  );
};

export default Register;
