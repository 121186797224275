import React, { useCallback, useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { storeContext } from "components/provider/Provider";
import { stockActions } from "store/stock-reducer";
import { layoutActions } from "store/layouts-reducer";
import { Box } from "@mui/material";
import { LucidePenSquare } from "lucide-react";

const { REACT_APP_API_URL } = process.env;

const extractProductSizes = (stock) => {
  const productSizes = [];
  stock.variant.option_values.forEach((optionValue) => {
    if (!productSizes.includes(optionValue.presentation)) {
      productSizes.push(optionValue.presentation);
    }
  });

  return productSizes.join(", ");
};

const handleStockObject = (stock) => ({
  id: stock.id,
  name: stock?.variant?.name,
  thumb: stock?.variant?.images[0]?.mini_url,
  sku: stock?.variant?.sku,
  price: stock?.variant?.price,
  salePrice: stock?.variant?.cost_price,
  category: stock?.variant?.option_values[0]?.option_type_presentation,
  sizes: extractProductSizes(stock),
  unchangedStock: stock?.variant?.total_on_hand || 0,
  stock: stock?.variant?.total_on_hand || 0,
});

const Stock = () => {
  const { state, dispatch } = useContext(storeContext);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [paginationTotal, setPaginationTotal] = useState(0);

  const loadStockData = () => {
    setLoading(true);
    axios
      .get(
        `${REACT_APP_API_URL}/api/stock_locations/1/stock_items/simple_variants`,
        {
          params: {
            page,
            per_page: pageSize,
          },
        }
      )
      .then((res) => {
        const formattedStock = res.data.stock_items
          .filter((stock) => !stock?.variant?.is_master)
          .map(
            (stock) =>
              stock?.variant?.is_master === false && handleStockObject(stock)
          );
        dispatch({
          type: stockActions.STOCK_SET_ALL,
          payload: {
            allStock: formattedStock,
          },
        });
        setPaginationTotal(res.data.total_count);
        setLoading(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    dispatch({
      type: layoutActions.LAYOUT_SET_ALL,
      payload: {
        pageTitle: "Stoku",
      },
    });
    loadStockData();
  }, [page, pageSize]);

  useEffect(() => {}, []);

  const handleProcessRowUpdateError = () => {
    dispatch({
      type: layoutActions.LAYOUT_SET_ALL,
      payload: {
        openMessage: true,
        error: true,
        signalMessage: "Stoku per këtë produkt nuk është ruajtur!",
      },
    });
  };

  const processRowUpdate = useCallback((newRow) => {
    setLoading(true);
    const stockDifference = newRow.stock - newRow.unchangedStock;
    axios
      .patch(`${REACT_APP_API_URL}/api/stock_items/${newRow?.id}`, {
        stock_item: { stick_item: newRow?.id, count_on_hand: stockDifference },
      })
      .then(() => {
        dispatch({
          type: layoutActions.LAYOUT_SET_ALL,
          payload: {
            openMessage: true,
            error: false,
            signalMessage: "Stoku per produktin u ruajt!",
          },
        });
        setLoading(false);
      })
      .catch(() => {
        dispatch({
          type: layoutActions.LAYOUT_SET_ALL,
          payload: {
            openMessage: true,
            error: false,
            signalMessage: "Stoku per produktin nuk është ruajtur!",
          },
        });
        setLoading(false);
      });

    return newRow;
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "thumb",
      headerName: "Foto",
      sortable: false,
      flex: 1,
      minWidth: 120,
      editable: false,
      renderCell: (params) => (
        <img src={params.value} alt={`Thumbnail for ${params.row.name}`} />
      ),
      sticky: "left",
    },
    {
      field: "name",
      headerName: "Emri",
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "sku",
      headerName: "SKU Kodi",
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "price",
      headerName: "Çmimi",
      sortable: false,
      type: "number",
      flex: 1,
      minWidth: 170,
    },
    {
      field: "salePrice",
      headerName: "Çmimi me Zbritje",
      sortable: false,
      type: "number",
      flex: 1,
      minWidth: 190,
    },
    {
      field: "category",
      headerName: "Kategoria",
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "sizes",
      headerName: "Madhësia",
      sortable: false,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "stock",
      renderHeader: () => (
        <Box display="flex" gap="10px" alignItems="center">
          Ndrysho Stokun <LucidePenSquare width={20} />
        </Box>
      ),
      sortable: false,
      type: "number",
      editable: true,
      flex: 1,
      minWidth: 120,
    },
  ];

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
        }}
      >
        <DataGrid
          rows={state.stock?.allStock}
          columns={columns}
          sx={{
            flex: 1,
            marginBottom: "24px",
            marginTop: "40px",
          }}
          rowHeight={80}
          loading={loading}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          disableRowSelectionOnClick
          pagination
          paginationMode="server"
          page={page}
          rowCount={paginationTotal}
          onPaginationModelChange={(newPagination) => {
            setPage(newPagination.page + 1);
            setPageSize(newPagination.pageSize);
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Stock;
