import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { LucidePackage, LucideShirt, LucideTruck } from "lucide-react";

const MainListItems = () => {
  const navigate = useNavigate();
  return (
    <>
      <ListItemButton onClick={() => navigate("/products")}>
        <ListItemIcon>
          <LucideShirt />
        </ListItemIcon>
        <ListItemText primary="Produktet" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/stock")}>
        <ListItemIcon>
          <LucidePackage />
        </ListItemIcon>
        <ListItemText primary="Stoku" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/orders")}>
        <ListItemIcon>
          <LucideTruck />
        </ListItemIcon>
        <ListItemText primary="Porosite" />
      </ListItemButton>
    </>
  );
};
export default MainListItems;
